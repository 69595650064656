import { remove, values, isEmpty } from 'lodash-es'

export const getters = {
  currentUser(state) {
    return state.auth.user
  },
  currentAccount(state) {
    return state.app.currentAccount
  },
  advancedEditor(state) {
    return state.app.advancedEditor
  },
  maintenanceMessage(state) {
    return state.app.maintenanceMessage
  },
}

export const mutations = {
  resetState(state) {
    Object.assign(state, { api: {} })
    window.localStorage.setItem('gleaninLogout', 'true')
  },
  afterSpeakerDeletion(state, speakerId) {
    const sessions = values(state.api.sessions) || []
    const filteredSessions = sessions.filter((session) =>
      session.speaker_ids.includes(parseInt(speakerId))
    )

    if (!isEmpty(filteredSessions)) {
      const eventId = filteredSessions[0].event_id
      const event = state.api.events[eventId]

      remove(
        event._meta.relationships.speakers.data,
        ({ id }) => parseInt(id) === parseInt(speakerId)
      )
      this.commit('api/replaceRecords', event)
    }

    filteredSessions.forEach((session) => {
      remove(session.speaker_ids, (id) => id === parseInt(speakerId))
      remove(
        session._meta.relationships.speakers.data,
        ({ id }) => parseInt(id) === parseInt(speakerId)
      )
      this.commit('api/replaceRecords', session)
    })
  },
  afterSessionDeletion(state, sessionId) {
    const speakers = values(state.api.speakers) || []
    const filteredSpeakers = speakers.filter((speaker) =>
      speaker.session_ids.includes(parseInt(sessionId))
    )
    if (!isEmpty(filteredSpeakers)) {
      const eventId = filteredSpeakers[0].event_id
      const event = state.api.events[eventId]

      remove(
        event._meta.relationships.sessions.data,
        ({ id }) => parseInt(id) === parseInt(sessionId)
      )
      this.commit('api/replaceRecords', event)
    }

    filteredSpeakers.forEach((speaker) => {
      remove(speaker.session_ids, (id) => id === parseInt(sessionId))
      remove(
        speaker._meta.relationships.sessions.data,
        ({ id }) => parseInt(id) === parseInt(sessionId)
      )
      this.commit('api/replaceRecords', speaker)
    })
  },
}
