import Vue from 'vue'
import { Notifier } from '@airbrake/browser'

let airbrake = {}

if (process.env.airbrakeKey) {
  airbrake = new Notifier({
    environment: process.env.environment,
    projectId: 310953,
    projectKey: process.env.airbrakeKey,
  })

  Vue.config.errorHandler = function (err, vm, info) {
    const user = vm?.$store?.$auth?.user

    airbrake.notify({
      error: err,
      params: { info, user, buildVersion: process.env.buildHash },
    })
  }
}

export default function (_, inject) {
  inject('airbrake', airbrake)
}
