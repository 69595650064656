export default function (context) {
  const { $axios, store, redirect } = context

  $axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
  $axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
  $axios.defaults.headers.common['GLEANIN-FE-VERSION'] = process.env.buildHash

  $axios.interceptors.response.use(
    (response) => {
      if (response.headers['gleanin-fe-version']) {
        const version = response.headers['gleanin-fe-version']
        if (version !== process.env.buildHash) {
          store.dispatch('app/showNewVersionAvailable')
        }
      }
      return response
    },
    (error) => {
      const { response } = error
      let message = null

      if (!response) {
        message =
          'The request to the server failed, please check your internet connection. This can also be caused by your network security settings. Please contact hello@gleanin.com if the issue persists.'
      } else if (response.status === 401) {
        message =
          'You are not authorized to do this action! Contact your administrator.'

        const responseText = response.request.responseText
        if (responseText) {
          const jsonResponse = JSON.parse(responseText)
          if (jsonResponse.errors) {
            message = jsonResponse.errors.join('. ')
          }
        }
      } else if (response.status >= 500) {
        if (response.status === 503) {
          if (response?.data?.message) {
            store.dispatch('app/setMaintenanceMessage', response.data.message)
          }
          return redirect('/maintenance')
        }
        message = 'Something went wrong.'
      } else if (response.status === 403) {
        if (!window.location.href.includes('/login')) {
          localStorage.setItem('gleaninSessionInvalidated', response.data.error)
          window.location.href = '/login'
        }

        return Promise.resolve({ data: [] })
      }

      if (message) {
        store.dispatch('app/showError', { error: { message } })
      }

      return Promise.reject(error)
    }
  )
}
