import { isEmpty } from 'lodash-es'

const UNLOCKED_ROUTE_NAMES = [
  'accounts-account_id-subscription',
  'accounts-account_id-subscription-payment_billing',
  'my_profile-details',
  'accounts',
]
const LOCKED_STATES = ['paused', 'cancelled']

export default async function ({ store, route, redirect, $find }) {
  let account = false
  const { params } = route

  if (!isEmpty(params.account_id)) {
    account = await $find('accounts', params.account_id, {
      params: { include: 'subscription' },
    })
  } else if (!isEmpty(params.event_id)) {
    const event = await $find('events', params.event_id)

    account = await $find('accounts', event.account_id, {
      params: { include: 'subscription' },
    })
  }

  if (account) {
    store.dispatch('app/setCurrentAccount', account)

    const subscription = account.subscription
    if (!store.state.auth.user.is_internal && subscription) {
      if (
        !UNLOCKED_ROUTE_NAMES.includes(route.name) &&
        LOCKED_STATES.includes(subscription.status)
      ) {
        store.dispatch(
          'app/showAlert',
          'Sorry. This account subscription is not active.'
        )
        return redirect(`/accounts/${account._meta.id}/subscription`)
      }
    }
  }
}
