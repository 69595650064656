import Vue from 'vue'
import {
  parseBillingTerm,
  formatDate,
  formatDateOnly,
  titleize,
  join,
  pluralizeFilter,
  capitalizeFilter,
  formatCurrency,
  downcase,
  abbreviatePeriod,
} from '~/services/filter-functions'

Vue.filter('parseBillingTerm', parseBillingTerm)

Vue.filter('formatDate', formatDate)

Vue.filter('formatDateOnly', formatDateOnly)

Vue.filter('titleize', titleize)

Vue.filter('join', join)

Vue.filter('pluralize', pluralizeFilter)

Vue.filter('capitalize', capitalizeFilter)

Vue.filter('formatCurrency', formatCurrency)

Vue.filter('downcase', downcase)

Vue.filter('abbreviatePeriod', abbreviatePeriod)
