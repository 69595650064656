class Upload {
  constructor(axios) {
    this.axios = axios
  }

  execute({
    files,
    additionalFields,
    endpoint,
    method,
    formField,
    onProgress,
  }) {
    onProgress(0)

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )

        onProgress(progress)
      },
    }

    const data = new FormData()

    Array.from(files).forEach((file) => {
      data.append(formField, file)
    })

    // Additional fields represented by Arrays in pairs
    // [[fieldName, fieldValue]]
    // We use arrays here because we cannot duplicate object
    // keys - which makes the usage of array form fields impossible:
    // [['names[]', 'name1'], ['names[]', 'name2']]
    if (additionalFields) {
      additionalFields.forEach((f) => {
        data.append(f[0], f[1])
      })
    }

    return this.axios[method](endpoint, data, config)
  }
}

export default function ({ $axios }, inject) {
  const upload = new Upload($axios)
  inject('upload', upload)
}
