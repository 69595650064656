import { initHttpClient } from '@/httpClient'

export default function (context) {
  const { $axios } = context

  // we can't install new axios package because it causes a conflict with @nuxtjs/axios auth plugin
  // that's why we need to pass @nuxtjs/axios
  // https://github.com/nuxt-community/auth-module/issues/1826
  initHttpClient($axios)
}
