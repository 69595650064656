/*
 * Mirage JS guide on Factories: https://miragejs.com/docs/data-layer/factories
 */
import { Factory } from 'miragejs'

/*
 * Faker Github repository: https://github.com/Marak/Faker.js#readme
 */
import faker from 'faker'
import { sample } from '../utils'

export default {
  user: Factory.extend({
    name() {
      return faker.name.findName()
    },
    email() {
      return faker.internet.email()
    },
    role() {
      return sample(['account-admin', 'manager'])
    },
    createdAt() {
      return faker.date.past(10)
    },
    confirmed() {
      return true
    },
  }),
}
