import actions from './actions'
import mutations from './mutations'

const state = () => {
  return {
    toast: {
      show: false,
      color: 'black',
      message: '',
      timeout: 3000,
    },
    versionToast: {
      show: false,
    },
    draftCampaign: {},
    modifiedTheme: {},
    currentAccount: {},
    advancedEditor: {
      resizing: false,
    },
    maintenanceMessage: '',
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
