<template>
  <!-- Toolbar -->
  <v-app-bar :clipped-left="true" app color="surface">
    <v-toolbar-title class="ml-0 pl-4">
      <span class="hidden-sm-and-down">
        <SvgIcon class="auto" name="GleaninLockup" color="#000" :width="120" />
      </span>
    </v-toolbar-title>

    <v-spacer></v-spacer>
    <v-menu offset-y left transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn data-test="menu-avatar" icon large v-bind="attrs" v-on="on">
          <v-avatar size="32px" item>
            <SvgIcon name="GleaninSmiley" :width="32" />
          </v-avatar>
        </v-btn>
      </template>
      <v-list dense nav>
        <v-list-item
          link
          :to="{ name: 'my_profile-details' }"
          data-test="my-profile"
        >
          <v-list-item-icon>
            <v-icon small>mdi-account-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> My profile </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="knownAccountId">
          <v-list-item link :to="`/accounts/${knownAccountId}/subscription`">
            <v-list-item-icon>
              <v-icon small>mdi-currency-usd</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Account subscription </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="`/accounts/${knownAccountId}/users`">
            <v-list-item-icon>
              <v-icon small>mdi-account-group-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Account users </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-divider class="my-1"></v-divider>
        <v-list-item link data-test="logout-btn" @click="logout">
          <v-list-item-icon>
            <v-icon small>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
            <v-list-item-action-text class="text-caption">
              {{ currentUser.email }}
            </v-list-item-action-text>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

import SvgIcon from '~/components/SvgIcon'

export default {
  components: {
    SvgIcon,
  },
  props: {
    knownAccountId: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapMutations(['resetState']),
  },
  methods: {
    async logout() {
      await this.$auth.logout('local')

      this.$store.commit('resetState')
    },
  },
}
</script>
