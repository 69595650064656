import { isEmpty } from 'lodash-es'

export default function ({ store }, inject) {
  const finder = async (recordType, id, options = {}) => {
    const cachedRecord = store.getters['api/get'](`${recordType}/${id}`)

    if (isEmpty(cachedRecord)) {
      const resolvedRecord = await store.dispatch('api/get', [
        `${recordType}/${id}`,
        options,
      ])

      return resolvedRecord
    } else {
      return cachedRecord
    }
  }

  inject('find', finder)
}
