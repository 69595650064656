export default {
  showToast: (state, toast) => {
    const { color, timeout, message } = toast

    state.toast = {
      message,
      color,
      timeout,
      show: true,
    }
  },
  hideToast: (state) => {
    state.toast.show = false
  },
  resetToast: (state) => {
    state.toast = {
      show: false,
      color: 'black',
      message: '',
      timeout: 3000,
    }
  },

  displayVersionToast: (state) => {
    state.versionToast.show = true
  },
  hideVersionToast: (state) => {
    state.versionToast.show = false
  },

  draftCampaign: (state, attributes) => {
    state.draftCampaign = attributes
  },
  draftCampaignNew: (state, attributes) => {
    state.draftCampaignNew = attributes
  },
  resetDraftCampaign: (state) => {
    state.draftCampaign = {}
  },
  resetDraftCampaignNew: (state) => {
    state.draftCampaignNew = {}
  },

  modifiedTheme: (state, attributes) => {
    state.modifiedTheme = attributes
  },
  resetModifiedTheme: (state) => {
    state.modifiedTheme = {}
  },
  modifiedThemeNew: (state, attributes) => {
    state.modifiedThemeNew = attributes
  },
  resetModifiedThemeNew: (state) => {
    state.modifiedThemeNew = {}
  },

  currentAccount: (state, account) => {
    state.currentAccount = account
  },

  resizingElement: (state, value) => {
    state.advancedEditor.resizing = value
  },

  maintenanceMessage: (state, message) => {
    state.maintenanceMessage = message
  },
}
