<template>
  <AlertMessage
    v-if="
      ['paused', 'pause_pending', 'non_renewing', 'in_trial'].includes(
        customisedSubscriptionStatus
      )
    "
    data-test="subscription-status-alert"
    :type="alertType"
    :class="alertClass"
  >
    <template v-if="customisedSubscriptionStatus === 'in_trial'">
      👋 You have
      <span class="red--text">
        {{ humanTrialDuration }}
      </span>
      left of your free evaluation, when you're ready please
      <NuxtLink :to="`/accounts/${accountId}/subscription`">
        upgrade here </NuxtLink
      >.
    </template>
    <template v-else-if="customisedSubscriptionStatus === 'paused'">
      Your subscription is currently paused until
      <strong v-text="parsedResumeDate" />. You can resume this subscription at
      any time from your
      <nuxt-link :to="`/accounts/${accountId}/subscription`"
        >Subscription settings</nuxt-link
      >.
    </template>
    <template v-else-if="customisedSubscriptionStatus === 'pause_pending'">
      Your subscription is scheduled to pause on
      <strong v-text="parsedPauseDate" />
      and will remain available to use until then. You can remove the scheduled
      pause at any time from your
      <nuxt-link :to="`/accounts/${accountId}/subscription`"
        >Subscription settings</nuxt-link
      >.
    </template>
    <template v-else-if="customisedSubscriptionStatus === 'non_renewing'">
      Your subscription is scheduled to cancel on
      <strong v-text="parsedCancelledAt" />
      and will remain available to use until then. You can stop your account
      cancelling up until
      <strong v-text="parsedCurrentTermEnd" />
      from your
      <nuxt-link :to="`/accounts/${accountId}/subscription`"
        >Subscription settings</nuxt-link
      >.
    </template>
  </AlertMessage>
</template>
<script>
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import AlertMessage from '@/components/AlertMessage'
dayjs.extend(duration)

export default {
  components: { AlertMessage },
  props: {
    accountId: { type: String, required: true },
    resumeDate: { type: Number, default: 0 },
    pauseDate: { type: Number, default: 0 },
    cancelledAt: { type: Number, default: 0 },
    currentTermEnd: { type: Number, default: 0 },
    subscriptionStatus: { type: String, required: true },
    trialEndAt: { type: Number, required: true },
  },
  computed: {
    isNonRenewing() {
      return this.customisedSubscriptionStatus === 'non_renewing'
    },
    alertType() {
      return this.isNonRenewing ? 'error' : 'info'
    },
    alertClass() {
      return this.isNonRenewing ? 'light-red--text' : 'light-blue--text'
    },
    humanTrialDuration() {
      const millisecondsInDay = 24 * 60 * 60 * 1000
      const duration = this.trialEndAt * 1000 - Math.round(Date.now())

      // https://day.js.org/docs/en/durations/creating
      if (duration < millisecondsInDay) {
        const durationInHours = Math.floor(duration / 3600000)
        return dayjs.duration({ hours: durationInHours }).format('H [hours]')
      } else {
        const durationInDays = Math.ceil(duration / 86400000)
        return dayjs.duration({ days: durationInDays }).format('D [days]')
      }
    },
    customisedSubscriptionStatus() {
      // Chargbee doesn't have "pause_pending" but we want to know.
      if (this.pauseDate && this.subscriptionStatus !== 'paused') {
        return 'pause_pending'
      }
      return this.subscriptionStatus
    },
    parsedResumeDate() {
      return this.$options.filters.parseBillingTerm(this.resumeDate)
    },
    parsedPauseDate() {
      return this.$options.filters.parseBillingTerm(this.pauseDate)
    },
    parsedCancelledAt() {
      return this.$options.filters.parseBillingTerm(this.cancelledAt)
    },
    parsedCurrentTermEnd() {
      return this.$options.filters.parseBillingTerm(this.currentTermEnd)
    },
  },
}
</script>
