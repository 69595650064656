<template>
  <div class="nav-menu">
    <v-list-item
      v-for="(menuItem, menuIndex) in menu"
      :key="menuIndex"
      :to="menuItem.link"
      active-class="primary--text"
      link
    >
      <v-list-item-icon>
        <SvgIcon class="social-icon" :name="menuItem.icon" :width="22" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="menuItem.text" />
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon'

export default {
  components: {
    SvgIcon,
  },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>
.nav-menu {
  .v-list-item {
    min-height: 45px;
  }

  .v-list-item__icon {
    height: 22px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .primary--text {
    cursor: default;
  }
}
</style>
