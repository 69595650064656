<template>
  <v-app v-if="$auth.loggedIn">
    <div class="d-flex flex-grow-1">
      <!-- Navigation -->
      <v-navigation-drawer app permanent :clipped="true">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="overline grey--text">
                Making connections matter
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list dense><nav-menu :menu="navItems" /></v-list>
      </v-navigation-drawer>

      <!-- Toolbar -->
      <TopBar :known-account-id="knownAccountId" />
      <v-main>
        <v-container fluid>
          <Nuxt />
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import { values } from 'lodash-es'
import NavMenu from '@/components/navigation/NavMenu'
import TopBar from '@/components/navigation/TopBar'

export default {
  components: { NavMenu, TopBar },
  middleware: ['account'],
  computed: {
    ...mapGetters({ get: 'api/get' }),
    accounts() {
      return values(this.get('accounts'))
    },
    knownAccountId() {
      return this.knownAccount ? this.knownAccount._meta.id : undefined
    },
    knownAccount() {
      if (this.$route.params.account_id !== undefined) {
        return this.get(`accounts/${this.$route.params.account_id}`)
      } else if (this.accounts.length === 1) {
        return this.accounts[0]
      } else {
        return undefined
      }
    },
    navItems() {
      // Nav items are dependent on whether we know which account we should
      // link to. We may know which account to link to in two circumstances:
      // 1 - The user only has access to one account.
      // 2 - The user is accessing a specific event page
      // We don't know the event to link to when a user has access to multiple
      // accounts and is on a non-account page (user preferences or /accounts).
      const nav = [
        this.eventsNavItems,
        {
          icon: 'mdi-account-cog',
          text: 'My profile',
          link: '/my_profile/details',
        },
      ]

      this.accountNavItems.forEach((item) => {
        nav.push(item)
      })
      return nav
    },
    eventsNavItems() {
      if (this.knownAccount) {
        // TODO When we nest events under accounts the link to /events will need
        // to be updated.
        return { icon: 'mdi-calendar', text: 'Events', link: '/events' }
      } else {
        return { icon: 'mdi-calendar', text: 'Events', link: '/accounts' }
      }
    },
    accountNavItems() {
      const accountNav = []
      if (this.accounts.length > 1) {
        accountNav.push({
          icon: 'mdi-home-city',
          text: 'My accounts',
          link: '/accounts',
        })
      }
      if (this.knownAccount) {
        accountNav.push({
          icon: 'mdi-home-outline',
          text:
            this.accounts.length > 1 ? this.knownAccount.name : 'My account',
          items: [
            {
              icon: 'mdi-currency-usd',
              text: 'Settings & subscription',
              link: `/accounts/${this.knownAccount._meta.id}/subscription`,
            },
            {
              icon: 'mdi-account-group-outline',
              text: 'Users',
              link: `/accounts/${this.knownAccount._meta.id}/users`,
            },
          ],
          regex: /accounts\/[0-9a-f-]*\/[a-z]/,
        })
      }
      return accountNav
    },
  },
}
</script>
