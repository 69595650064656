/**
 * Copy to clipboard the text passed
 * @param {String} text string to copy
 * @param {String} toastText message to appear on the toast notification
 */
export default function ({ store }, inject) {
  const clipboard = function (text, toastText = 'Copied to clipboard!') {
    const el = document.createElement('textarea')

    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)

    store.dispatch('app/showSuccess', toastText)
  }

  inject('clipboard', clipboard)
}
