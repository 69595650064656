<template>
  <transition name="fade" mode="out-in">
    <v-app>
      <div
        class="d-flex text-center flex-column flex-md-row flex-grow-1 v-sheet theme--light"
      >
        <v-sheet
          class="layout-side mx-auto d-none d-md-flex flex-md-column px-2 v-sheet theme--light"
        >
          <div class="pa-2 pt-4 pl-4 d-flex">
            <SvgIcon
              class="auto"
              name="GleaninLockup"
              color="#fff"
              :width="182"
            />
          </div>
          <OnBoardingCarousel />
        </v-sheet>

        <div
          class="flex-grow-1 align-center justify-center d-flex flex-column v-sheet theme--light"
        >
          <div class="layout-content ma-auto w-full">
            <nuxt />
          </div>
          <div class="overline mt-4">Gleanin</div>
        </div>
      </div>

      <v-snackbar
        v-if="shouldNotify && toast.show"
        :value="true"
        :timeout="-1"
        :color="toast.color"
        :min-width="50"
        bottom
      >
        {{ toast.message }}
      </v-snackbar>
    </v-app>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import { isEmpty } from 'lodash-es'
import OnBoardingCarousel from '~/components/layout/OnBoardingCarousel'

import SvgIcon from '~/components/SvgIcon'

export default {
  components: {
    OnBoardingCarousel,
    SvgIcon,
  },
  asyncData() {
    const sessionInvalidationMessage = window.localStorage.getItem(
      'gleaninSessionInvalidated'
    )

    return { sessionInvalidationMessage }
  },
  computed: {
    ...mapState('app', ['toast']),
    shouldNotify() {
      return (
        !isEmpty(this.sessionInvalidationMessage) ||
        this.toast.color === 'success'
      )
    },
  },
  mounted() {
    if (this.shouldNotify) {
      this.$store.dispatch('app/showAlert', this.sessionInvalidationMessage)
      window.localStorage.removeItem('gleaninSessionInvalidated')
    }
  },
}
</script>

<style scoped>
.layout-side {
  width: 50%;
  background: linear-gradient(
    243.18deg,
    rgba(83, 71, 244, 0) 0%,
    rgba(158, 37, 246, 0.8) 0.01%,
    rgba(83, 71, 244, 0.8) 100%
  );
}
.layout-content {
  max-width: 650px;
}

.font-size-60 {
  font-size: 60px;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.catamaran {
  font-family: 'Catamaran', sans-serif !important;
}
</style>
