<template>
  <transition name="fade" mode="out-in">
    <v-app>
      <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
        <v-sheet
          class="layout-side mx-auto mx-md-1 d-none d-md-flex flex-md-column justify-space-between px-2"
        >
          <div class="mt-3 mt-md-10 pa-2">
            <div
              class="catamaran mt-4 display-2 font-weight-medium gleanin-purple--text"
            >
              <NuxtLink style="text-decoration: none; color: inherit" to="/">
                Gleanin
              </NuxtLink>
            </div>
            <div class="catamaran title my-2">
              The community marketing platform
            </div>
          </div>
          <div class="pb-5">
            <SvgIcon
              class="auto"
              name="GleaninLockup"
              color="#000000"
              :width="186"
            />
          </div>
        </v-sheet>

        <div class="pa-2 pa-md-4 flex-grow-1 justify-center d-flex flex-column">
          <div class="layout-content ma-auto w-full">
            <nuxt />
          </div>
          <div class="catamaran overline mt-4">Gleanin</div>
        </div>
      </div>
    </v-app>
  </transition>
</template>

<script>
import SvgIcon from '~/components/SvgIcon'

export default {
  components: {
    SvgIcon,
  },
}
</script>

<style scoped>
.layout-side {
  width: 420px;
}
.layout-content {
  max-width: 768px;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.catamaran {
  font-family: 'Catamaran', sans-serif !important;
}
</style>
