/*
 * Mirage JS guide on Factories: https://miragejs.com/docs/data-layer/factories
 */

import user from './user'
import account from './account'
import event from './event'
import campaign from './campaign'
import session from './session'
import speaker from './speaker'
import theme from './theme'
import attachment from './attachment'
/*
 * factories are contained in a single object, that's why we
 * destructure what's coming from users and the same should
 * be done for all future factories
 */
export default {
  ...user,
  ...account,
  ...event,
  ...campaign,
  ...session,
  ...speaker,
  ...theme,
  ...attachment,
}
