/*
 * Mirage JS guide on Factories: https://miragejs.com/docs/data-layer/factories
 */
import { Factory } from 'miragejs'

/*
 * Faker Github repository: https://github.com/Marak/Faker.js#readme
 */
import faker from 'faker'

export default {
  session: Factory.extend({
    title() {
      return faker.commerce.productName()
    },
    schedule() {
      return faker.date.future(10)
    },
    createdAt() {
      return faker.date.past(10)
    },
  }),
}
