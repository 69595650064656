/* eslint-disable no-console */

import { makeServer } from '../miragejs/server'

export default () => {
  if (process.env.mirage) {
    console.info('%c Starting mirage.', 'background: red; color: #fff')
    makeServer()
  }
}
