const showAlert = ({ commit }, message) => {
  commit('showToast', {
    color: 'orange',
    message,
    timeout: 3000,
  })

  setTimeout(() => commit('hideToast'), 10500)
}

const showError = ({ commit }, { message = 'Failed!', error }) => {
  commit('showToast', {
    color: 'error',
    message: `${message} ${error.message}`,
    timeout: 10000,
  })

  setTimeout(() => commit('hideToast'), 20500)
}

const showSuccess = ({ commit }, message) => {
  commit('showToast', {
    color: 'success',
    message,
    timeout: 3000,
  })

  setTimeout(() => commit('hideToast'), 10500)
}

const showNewVersionAvailable = ({ commit }) => {
  commit('displayVersionToast')
}
const hideNewVersionAvailable = ({ commit }) => {
  commit('hideVersionToast')
}

const setDraftCampaign = ({ commit }, attributes) => {
  localStorage.setItem('draftCampaign', JSON.stringify(attributes))
  commit('draftCampaign', attributes)
}

const setDraftCampaignNew = ({ commit }, attributes) => {
  localStorage.setItem('draftCampaignNew', JSON.stringify(attributes))
  commit('draftCampaignNew', attributes)
}

const resetDraftCampaign = ({ commit }) => {
  localStorage.removeItem('draftCampaign')
  commit('resetDraftCampaign')
}

const resetDraftCampaignNew = ({ commit }) => {
  localStorage.removeItem('draftCampaignNew')
  commit('resetDraftCampaignNew')
}

const setModifiedTheme = ({ commit }, attributes) => {
  localStorage.setItem('modifiedTheme', JSON.stringify(attributes))
  commit('modifiedTheme', attributes)
}

const resetModifiedTheme = ({ commit }) => {
  localStorage.removeItem('modifiedTheme')
  commit('resetModifiedTheme')
}

const setModifiedThemeNew = ({ commit }, attributes) => {
  localStorage.setItem('modifiedThemeNew', JSON.stringify(attributes))
  commit('modifiedThemeNew', attributes)
}

const resetModifiedThemeNew = ({ commit }) => {
  localStorage.removeItem('modifiedThemeNew')
  commit('resetModifiedThemeNew')
}

const setCurrentAccount = ({ commit }, account) => {
  commit('currentAccount', account)
}

const setResizingElementFlag = ({ commit }, value) => {
  commit('resizingElement', value)
}

const setMaintenanceMessage = ({ commit }, message) => {
  commit('maintenanceMessage', message)
}

export default {
  showAlert,
  showError,
  showSuccess,
  showNewVersionAvailable,
  hideNewVersionAvailable,
  setDraftCampaign,
  setDraftCampaignNew,
  resetDraftCampaign,
  resetDraftCampaignNew,
  setModifiedTheme,
  resetModifiedTheme,
  setModifiedThemeNew,
  resetModifiedThemeNew,
  setCurrentAccount,
  setResizingElementFlag,
  setMaintenanceMessage,
}
