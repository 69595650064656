/*
 * Mirage JS guide on Factories: https://miragejs.com/docs/data-layer/factories
 */
import { Factory } from 'miragejs'

/*
 * Faker Github repository: https://github.com/Marak/Faker.js#readme
 */
import faker from 'faker'

export default {
  account: Factory.extend({
    name() {
      return faker.name.findName()
    },
  }),
}
