/*
 * Mirage JS guide on Models: https://miragejs.com/docs/data-layer/models
 */

import { Model, hasMany, belongsTo } from 'miragejs'

/*
 * Everytime you create a new resource you have
 * to create a new Model and add it here. It is
 * true for Factories and for Fixtures.
 *
 * Mirage JS guide on Relationships: https://miragejs.com/docs/main-concepts/relationships/
 */
export default {
  user: Model.extend({
    accounts: hasMany(),
  }),
  account: Model.extend({
    owner: belongsTo('user', { inverse: null }),
    users: hasMany(),
  }),
  campaign: Model.extend({
    event: belongsTo(),
    variants: hasMany(),
  }),
  event: Model.extend({
    account: belongsTo(),
    campaigns: hasMany(),
    sessions: hasMany(),
    speakers: hasMany(),
    images: hasMany('attachment'),
  }),
  session: Model.extend({
    event: belongsTo(),
    speakers: hasMany(),
  }),
  speaker: Model.extend({
    event: belongsTo(),
    sessions: hasMany(),
  }),
  theme: Model.extend({
    campaign: belongsTo(),
    variants: hasMany(),
  }),
  variant: Model.extend({
    campaign: belongsTo(),
    theme: belongsTo(),
  }),
  attachment: Model.extend({
    event: belongsTo(),
  }),
}
