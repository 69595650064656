export default function ({ redirect, store }) {
  const logoutCallback = (e) => {
    if (e.key === 'gleaninLogout' && e.newValue === 'true') {
      store.$auth.logout('local').then(() => {
        store.commit('resetState')
        window.localStorage.removeItem('gleaninLogout')

        return redirect('/login')
      })
    }
  }

  window.addEventListener('storage', logoutCallback)
}
