<template>
  <transition name="fade" mode="out-in">
    <v-app>
      <Nuxt />
      <v-snackbar
        v-if="shouldNotify && toast.show"
        :value="true"
        :timeout="-1"
        :color="toast.color"
        :min-width="50"
        bottom
      >
        {{ toast.message }}
      </v-snackbar>
    </v-app>
  </transition>
</template>
<script>
import { mapState } from 'vuex'
import { isEmpty } from 'lodash-es'

export default {
  middleware: ['confirmation', 'account'],
  asyncData() {
    const sessionInvalidationMessage = window.localStorage.getItem(
      'gleaninSessionInvalidated'
    )

    return { sessionInvalidationMessage }
  },
  computed: {
    ...mapState('app', ['toast']),
    shouldNotify() {
      return (
        !isEmpty(this.sessionInvalidationMessage) ||
        this.toast.color === 'success'
      )
    },
  },
  mounted() {
    if (this.shouldNotify) {
      this.$store.dispatch('app/showAlert', this.sessionInvalidationMessage)
      window.localStorage.removeItem('gleaninSessionInvalidated')
    }
  },
}
</script>
