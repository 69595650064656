/*
 * Mirage JS guide on Factories: https://miragejs.com/docs/data-layer/factories
 */
import { Factory } from 'miragejs'

/*
 * Faker Github repository: https://github.com/Marak/Faker.js#readme
 */
import faker from 'faker'

export default {
  speaker: Factory.extend({
    name() {
      return faker.name.findName()
    },
    avatar() {
      return faker.image.avatar()
    },
    jobTitle() {
      return faker.name.jobTitle()
    },
    companyName() {
      return faker.company.companyName()
    },
    email() {
      return faker.internet.email()
    },
    createdAt() {
      return faker.date.past(10)
    },
  }),
}
