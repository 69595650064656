import dayjs from 'dayjs'
import pluralize from 'pluralize'
import { capitalize, isEmpty } from 'lodash-es'

export const parseBillingTerm = (value) => {
  return dayjs.unix(value).format('DD MMM YYYY')
}

export const formatDate = (value) => {
  if (isEmpty(value)) return ''

  return dayjs(value).format("D MMM 'YY, HH:mm")
}

export const formatDateOnly = (value) => {
  if (isEmpty(value)) return ''

  return dayjs(value).format("D MMM 'YY")
}

export const titleize = (value) => {
  value = value.toLowerCase().replace('_', ' ').split(' ')

  for (let i = 0; i < value.length; i++) {
    value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1)
  }

  return value.join(' ')
}

export const join = (value, delimiter) => {
  if (isEmpty(value)) return ''

  return value.join(delimiter)
}

export const pluralizeFilter = (value, number) => {
  return pluralize(value, number)
}

export const capitalizeFilter = (value) => {
  if (isEmpty(value)) return ''

  return capitalize(value)
}

export const formatCurrency = (value, currency) => {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
  })

  return formatter.format(value / 100)
}

export const downcase = (value) => {
  if (isEmpty(value)) return value

  return value.toLowerCase(value)
}

export const abbreviatePeriod = (value) => {
  if (isEmpty(value)) return ''
  switch (value.toLowerCase()) {
    case 'year':
      return 'yr'
    case 'month':
      return 'mo'
    default:
      return ''
  }
}
