<template>
  <v-autocomplete
    v-model="selected"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    item-text="description"
    placeholder="Global search"
    return-object
    auto-select-first
    no-filter
    @change="openSelectedItem"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Search for accounts, events, or campaigns
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item.description"></v-list-item-title>
        <v-list-item-subtitle v-text="item.type"></v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <nuxt-link :to="item.path">
          <v-icon>mdi-link</v-icon>
        </nuxt-link>
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      items: [],
      selected: null,
      isLoading: false,
    }
  },
  watch: {
    async search() {
      this.isLoading = true
      if (this.search !== null && this.search.length >= 1) {
        const result = await this.$axios.get(
          `/admin/search?keyword=${this.search}`
        )
        this.items = []
        result.data.data.forEach((searchable) => {
          const item = {
            id: searchable.id,
            description: searchable.attributes.description,
          }
          switch (searchable.attributes.klass) {
            case 'Account':
              item.path = `/admin/accounts/${searchable.id}`
              break
            case 'Event':
              item.path = `/events/${searchable.id}/campaigns`
              break
            case 'Campaign':
              item.path = `/events/${searchable.attributes.parent_id}/campaigns/${searchable.id}`
              break
          }
          this.items.push(item)
        })
      }
      this.isLoading = false
    },
  },
  methods: {
    openSelectedItem() {
      this.$router.push(this.selected.path)
    },
  },
}
</script>
