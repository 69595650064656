<template>
  <div>
    <v-card
      v-if="error.statusCode === 404"
      class="text-center w-full error-page pa-3"
    >
      <div class="display-1 mt-2">404 - Not Found</div>
      <div class="display-2 mt-1">How did you get here?</div>
      <div class="mt-3 mb-6">
        Sorry we can't seem to find the page you're looking for.
      </div>
      <v-btn nuxt to="/" block large color="primary">Home Page</v-btn>
    </v-card>
    <div v-else>
      <h1>
        {{ otherError }}
      </h1>
      <a href="/"> Home Page </a>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'empty',
  auth: false,
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred',
    }
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title,
    }
  },
}
</script>

<style scoped>
.error-page {
  max-width: 500px;
}
h1 {
  font-size: 20px;
}
</style>
